import axios from 'axios';

const resource = "/report-heatmap/get-list-layout-by-group?groupID=";


export default {
    get(groupID) {
        return axios.get(
            `${resource}${groupID}`
        );
    }
};