<template>
    <BaseDialog :open="open">
        <div class="dialog-delete-container">
            <div class="dialog-header">
                <div class="main-title">
                    {{ $t("Employee.DeleteEmployeeTitle") }}
                </div>
                <div>
                    {{ $t("Employee.DeleteEmployeeText") }}
                    <b>{{ data.employeeID }}</b>
                </div>
            </div>

            <div class="base-button-container">
                <div class="btn-option btn-delete" @click="deleteEmployee">
                    {{ $t("Customers.AcceptToDelete") }}
                </div>
                <div class="btn-option btn-cancel" @click="onCancel">
                    {{ $t("Customers.Cancel") }}
                </div>
            </div>
        </div>
    </BaseDialog>
</template>

<script>
import { BaseDialog } from "@/components";
import { Api } from "@/api/index";
import EventBus from "@/EventBus";

const deleteEmployeeV2 = Api.get("deleteEmployeeV3");

export default {
    components: {
        BaseDialog
    },

    props: {
        open: {
            type: Boolean
        },
        data: {
            type: String
        }
    },

    methods: {
        onCancel() {
            this.$emit("cancel", false);
        },
        deleteEmployee() {
            this.$swal({
                title: this.$t("CustomerResponse.CM99999"),
                allowOutsideClick: true
            });
            this.$swal.showLoading();
            deleteEmployeeV2
                .post(this.data)
                .then(response => {
                    this.$swal.fire({
                        icon: "success",
                        width: 500,
                        title: this.$t("EmployeeResponse.EM00100"),
                        showConfirmButton: false,
                        padding: 0.5,
                        timer: 2000
                    });
                    this.$emit("cancel", false);
                    EventBus.$emit("idEmployeeDelete", this.data);
                })
                .catch(error => {
                    this.$swal.close();
                    this.$swal(
                        this.$t(
                            "EmployeeResponse." +
                                error.response.data.message.replace(".", "")
                        ),
                        "",
                        "warning"
                    );
                });
        }
    },
    watch: {
        open(val) {}
    }
};
</script>

<style lang="scss">
.dialog-delete-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;

    .dialog-header {
        font-size: 18px;
        .main-title {
            font-size: 25px;
            margin-bottom: 15px;
            font-weight: bold;
        }
    }

    .base-button-container {
        display: flex;
        justify-content: space-between;

        .btn-option {
            width: 30%;
            text-align: center;
            border: 1px solid #fff;
            border-radius: 10px;
            padding: 10px;
            cursor: pointer;
        }

        .btn-delete {
            background-color: #6370e3;
            color: #fff;
        }

        .btn-cancel {
            border: 1px solid #595454;
            background-color: #fff;
            color: #000;
        }
    }
}
</style>
