<template>
    <div>
        <div class="detail-report-main-header">
            <div class="md-layout">
                <div class="search-container md-layout-item md-size-15">
                    <v-date-picker v-model="range" is-range :locale="lang">
                        <template v-slot="{ inputEvents }">
                            <div class="flex justify-center items-center">
                                <input
                                    :value="displayDateRange()"
                                    v-on="inputEvents.start"
                                    class="base-input"
                                />
                            </div>
                        </template>
                    </v-date-picker>
                    <div style="float: right">
                        <img src="/img/calendar.png" alt=""/>
                    </div>
                </div>
                <div class="md-layout-item md-size-20">
                    <md-field>
                        <label>{{ $t("Heatmap.SelectStore") }}</label>
                        <md-select v-model="selectListGroup">
                            <md-option
                                v-for="group in listGroup"
                                v-bind:key="group.id"
                                :value="group.id"
                            >
                                {{ group.name }}
                            </md-option>
                        </md-select>
                    </md-field>
                </div>
                <div
                    class="md-layout-item md-size-10"
                    style="padding-top: 2%; padding-left: 3%"
                >
                </div>
            </div>
            <div class="main-content-select-date">
                <div class="select-date-main-title">
                    {{ $t("Employee.GreetingTimesAt")}} <b>{{ dateStart }}</b> {{ $t("Heatmap.To") }}
                    <b>{{ dateEnd }}</b>
                </div>
            </div>
            <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-40" v-if="shouldDisplayDonutChart">
                    <div id="donut-chart">
                        <apexchart
                            :options="pieChartOptions"
                            :series="pieChartSeries"
                            type="pie"
                            width="500"
                        ></apexchart>
                    </div>
                </div>
                <div class="md-layout-item md-small-size-100 md-size-60">
                    <div id="chart">
                        <apexchart
                            :options="columnChartOptions"
                            :series="columnChartSeries"
                            height="350"
                            type="bar"
                        ></apexchart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment-timezone";
import {Api} from "@/api/index";
import Vue from "vue";

const greetingReport = Api.get("greetingReport")

moment.locale("vi");

export default {
    name: "GreetingReport",
    mounted() {
        this.lang = localStorage.lang == "vn" ? "vi" : "en"
        this.dateStart = this.getFullDate(this.range.start);
        this.dateEnd = this.getFullDate(this.range.end);

        this.callAPIGreetingReport(
            "",
            this.rangeTimestamp.start,
            this.rangeTimestamp.end
        )
    },
    props: {
        listGroup: {
            type: Array
        }
    },
    data() {
        var self = this
        return {
            lang: "",
            range: {
                start: moment().startOf("day").subtract(5, "day"),
                end: moment().startOf("day"),
            },

            rangeTimestamp: {
                start: moment().startOf("day").subtract(5, "day").unix(),
                end: moment().startOf("day").unix(),
            },

            dateStart: "",
            dateEnd: "",

            shouldDisplayDonutChart: false,

            selectGroupName: "tất cả các cửa hàng",

            selectListGroup: "",

            pieChartOptions: {
                chart: {
                    width: 380,
                    type: 'pie',
                },
                labels: [self.$t("Employee.NoWelcome") ,self.$t("Employee.Welcome") ],
                colors: ["#048C3B", "#FB8832"],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },
            pieChartSeries: [1, 1],

            columnChartOptions: {},
            columnChartSeries: [],

            welcomeText: self.$t("Employee.Welcome"),
            noWelcomeText: self.$t("Employee.NoWelcome")

        }
    },
    methods: {
        callAPIGreetingReport(groupID, dateStart, dateEnd) {
            greetingReport.get(groupID, dateStart, dateEnd)
                .then(response => {
                    this.fillDataToPieChart(response.data?.greetings, response.data?.notGreetings)
                    this.fillDataToColumnChart(response.data?.date, response.data?.greetings, response.data?.notGreetings)
                })
        },
        fillDataToPieChart(listGreeting, listNotGreeting) {
            const totalGreeting = listGreeting.reduce((a, b) => a + b, 0)
            const totalNotGreeting = listNotGreeting.reduce((a, b) => a + b, 0)


            Vue.set(this.pieChartSeries, 0, totalGreeting)
            Vue.set(this.pieChartSeries, 1, totalNotGreeting)

            this.shouldDisplayDonutChart = !(totalGreeting === 0 && totalNotGreeting === 0)

        },
        fillDataToColumnChart(listDate, listGreeting, listNotGreeting) {
            this.changeOddPositionToBlank(listDate)

            this.columnChartOptions = {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                colors: ["#FB8832", "#048C3B"],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: listDate,
                },
                yaxis: {
                    title: {
                        text: ''
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val
                        }
                    }
                }
            }

            this.columnChartSeries = [{
                name: this.welcomeText,
                data: listNotGreeting
            }, {
                name: this.noWelcomeText,
                data: listGreeting
            }]
        },
        changeOddPositionToBlank(listDate) {
            if (listDate.length > 8) {
                for (let index = 0; index < listDate.length; index++) {
                    if (index % 2 !== 0) {
                        listDate[index] = ""
                    }
                }
            }
        },
        getFullDate(val) {
            let dateString;
            let year;
            let month;
            let date;
            try {
                date = val._d.getDate();
                if (date < 10) {
                    date = "0" + date.toString();
                }
                month = val._d.getMonth() + 1;
                if (month < 10) {
                    month = "0" + month.toString();
                }
                year = val._d.getFullYear();
                dateString = date + "/" + month + "/" + year;
                return dateString;
            } catch (error) {
                date = val.getDate();
                if (date < 10) {
                    date = "0" + date.toString();
                }
                month = val.getMonth() + 1;
                if (month < 10) {
                    month = "0" + month.toString();
                }
                year = val.getFullYear();
                dateString = date + "/" + month + "/" + year;
                return dateString;
            }
        },
        displayDateRange() {
            return moment(this.range.start).locale("vi").format("DD/MM") +
                " - " +
                moment(this.range.end).locale("vi").format("DD/MM");
        },
    },
    watch: {
        selectListGroup(val) {
            for (let i = 0; i < this.listGroup.length; i++) {
                if (this.listGroup[i].id === val) {
                    this.selectGroupName = `cửa hàng ${this.listGroup[i].name}`;
                }
            }

            if (val === "") {
                this.selectGroupName = "tất cả các cửa hàng"
            }

            this.callAPIGreetingReport(
                val,
                this.rangeTimestamp.start,
                this.rangeTimestamp.end,
            );
        },
        range(val) {
            this.rangeTimestamp = {
                start: moment(val.start).startOf("day").format("x") / 1000,
                end: moment(val.end).startOf("day").format("x") / 1000,
            };

            const rangeBetweenDays = this.rangeTimestamp.end - this.rangeTimestamp.start;


            if (
                rangeBetweenDays < 86400 * 4 ||
                rangeBetweenDays > 86400 * 29
            ) {
                this.$notify({
                    timeout: 2500,
                    message:
                        "Vui lòng chọn khoảng thời gian nhiều hơn 4 ngày và ít hơn 31 ngày",
                    icon: "add_alert",
                    horizontalAlign: "right",
                    verticalAlign: "top",
                    type: "primary",
                });
            } else {
                this.dateStart = this.getFullDate(val.start);
                this.dateEnd = this.getFullDate(val.end);

                this.callAPIGreetingReport(
                    this.selectListGroup,
                    this.rangeTimestamp.start,
                    this.rangeTimestamp.end,
                );
            }
        }
    },

}
</script>

<style scoped>

</style>