import axios from "axios";

const resource = "/user-management/firebase/check-token-exist";

export default {
    post(data) {
        return axios.post(resource, data, {
            headers: {
                "Content-Type": "application/json",
            },
        });
    },
};
