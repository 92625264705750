import axios from 'axios';
import utils from "@/utils";

const resource = "report-route-map/count-by-date?";

export default {
    get(dateStart, dateEnd, cameraID) {
        const paramsObject = {
            cameraID: cameraID,
            dateStart: dateStart,
            dateEnd: dateEnd,
        };
        
        const queryString = utils.serializeQueryParams(paramsObject);

        return axios.get(resource + queryString);
    }
};