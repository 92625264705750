import axios from "axios";

const resource = "user-management/add-sub-account";

export default {
    post(data) {
        return axios.post(resource, data, {
            "Content-Type": "application/json",
        });
    },
};
