<template>
    <div id="LineChart">
        <apexchart
            type="line"
            height="450"
            :options="chartOptions"
            :series="series"
        ></apexchart>
    </div>
</template>
<script>
export default {
    name: "LineChart",
    props: {
        series: {
            type: Array
        },
        dateRange: {
            type: Array
        },
        dateFormat: {
            type: Array
        }
    },
    mounted() {
        var dateFormat = this.dateFormat
        var dateRange = this.dateRange
        
        this.chartOptions = {
            chart: {
                height: 350,
                type: "line",
                zoom: {
                    enabled: false
                }
            },
            tooltip: {
                enabled: true,
                x: {
                    show: true,
                    formatter: function(value, opts) {
                        return dateFormat[value-1];
                    }
                }
            },
            yaxis: {
                min: 0,
                tickAmount: 5,
            },
            stroke: {
                curve: "smooth"
            },
            title: {
                text: "Product Trends by Month",
                align: "left"
            },
            grid: {
                row: {
                    colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                    opacity: 0.5
                }
            },
            xaxis: {
                categories: dateRange,
                labels: {
                    formatter: function(value, timestamp, opts) {
                        
                        if (dateRange.length <= 30) {
                            return value
                        } else if (dateRange.length > 30 && dateRange.length <= 180) {
                            if (dateRange.indexOf(value) % 7 == 0) {
                                return value
                            }
                        } else {
                            if ((value != undefined && value.includes("01/") == true) || value == "31/12") {
                                return value
                            }
                        }
                    }   
                },
                tooltip: {
                    formatter: function(value, opts) {
                        return dateRange[value-1] 
                    }
                }
            }
        }
    },
    watch: {
        dateRange(val) {
            var dateFormat = this.dateFormat
            this.chartOptions = {
                chart: {
                    height: 350,
                    type: "line",
                    zoom: {
                        enabled: false
                    }
                },
                tooltip: {
                    enabled: true,
                    x: {
                        show: true,
                        formatter: function(value, opts) {
                            return dateFormat[value-1];
                        }
                    }
                },
                yaxis: {
                    min: 0,
                    tickAmount: 5,
                },
                stroke: {
                    curve: "smooth"
                },
                title: {
                    text: "Product Trends by Month",
                    align: "left"
                },
                grid: {
                    row: {
                        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                        opacity: 0.5
                    }
                },
                xaxis: {
                    categories: val,
                    labels: {
                        formatter: function(value, timestamp, opts) {
                            if (val.length <= 30) {
                                return value
                            } else if (val.length > 30 && val.length <= 180) {
                                if (val.indexOf(value) % 7 == 0) {
                                    return value
                                }
                            } else {
                                if ((value != undefined && value.includes("01/") == true) || value == "31/12") {
                                    return value
                                }
                            }
                        }   
                    },
                    tooltip: {
                        formatter: function(value, opts) {
                            return val[value-1] 
                        }
                    }
                }
            }
        },
        dateFormat(val) {
            
            var self = this
            this.chartOptions = {
                chart: {
                    height: 350,
                    type: "line",
                    zoom: {
                        enabled: false
                    }
                },
                tooltip: {
                    enabled: true,
                    x: {
                        show: true,
                        formatter: function(value, opts) {
                            return val[value-1];
                        }
                    }
                },
                yaxis: {
                    min: 0,
                    tickAmount: 5,
                },
                stroke: {
                    curve: "smooth"
                },
                title: {
                    text: "Product Trends by Month",
                    align: "left"
                },
                grid: {
                    row: {
                        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                        opacity: 0.5
                    }
                },
                xaxis: {
                    categories: self.dateRange,
                    labels: {
                        formatter: function(value, timestamp, opts) {
                            if (self.dateRange.length <= 30) {
                                return value
                            } else if (self.dateRange.length > 30 && val.length <= 180) {
                                if (self.dateRange.indexOf(value) % 7 == 0) {
                                    return value
                                }
                            } else {
                                if ((value != undefined && value.includes("01/") == true) || value == "31/12") {
                                    return value
                                }
                            }
                        }   
                    },
                    tooltip: {
                        formatter: function(value, opts) {
                            return val[value-1] 
                        }
                    }
                }
            }
        }
    },
    data() {
        return {
            chartOptions: {}
        };
    }
};
</script>
