import axios from "axios";
import utils from "@/utils";

const resource = "/people-counting-management/zone-analysis/report/interaction-rate?";

export default {
    get(groupID, dateStart, dateEnd) {

        const paramsObject = {
            groupID: groupID,
            dateStart: dateStart,
            dateEnd: dateEnd,
        };

        const queryString = utils.serializeQueryParams(paramsObject);

        return axios.get(`${resource}${queryString}`);
    },
};
