import axios from "axios";

const resource = "/user-management/firebase/update-firebase-token";

export default {
    put(data) {
        return axios.put(resource, data, {
            headers: {
                "Content-Type": "application/json",
            },
        });
    },
};
