import axios from "axios";

const resource = "user-management/update-sub-account";

export default {
    put(data) {
        return axios.put(resource, data, {
            "Content-Type": "application/json",
        });
    },
};
