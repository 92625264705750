<template>
    <transition name="form-dialog">
        <div v-show="open" class="wrapper-form-dialog">
            <div class="form-dialog">
                <slot />
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "FormDialog",

    props: {
        open: {
            type: Boolean,
        },
    },
};
</script>

<style lang="scss" scoped>
/* transition */

/* ********************* */
.wrapper-form-dialog {
    cursor: default;
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0.8rem;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.2);

    .form-dialog {
        position: relative;
        width: 35%;
        max-width: 50rem;
        overflow-y: auto;
        background-color: white;
        border-radius: 1rem;
        font-size: 16px;
        display: flex;
        flex-direction: column;

        .button-close-dialog {
            color: #000;
            margin-left: auto;
            cursor: pointer;
            padding: 20px 20px 0;

            &:hover {
                opacity: 50%;
            }
        }
    }
}
</style>
