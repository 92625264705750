<template>
    <div>
        <md-dialog :md-active.sync="showDialog">
            <div class="content-dialog">
                <div class="header">
                    <img
                        class="avatar-user"
                        src="/img/default-avatar.png"
                        alt=""
                    />
                    <span class="Username">{{userName}}</span>
                    <span class="position"></span>
                </div>
                <div class="contact">
                    <div class="phone">
                        <span class="title">{{$t("Settings.Contact")}}</span>
                        <span class="value">{{phoneNumber}}</span>
                    </div>
                    <div class="mail">
                        <span class="title">{{$t("Settings.Email")}}</span>
                        <span class="value">{{emailAddress}}</span>
                    </div>
                </div>
            </div>

            <md-dialog-actions>
                <md-button class="md-primary" @click="logoutMethod"
                    >{{ this.$t("dashboard.Logout") }}</md-button
                >
                <md-button class="md-primary" @click="showDialog = false"
                    >{{ this.$t("Settings.Cancel") }}</md-button
                >
            </md-dialog-actions>
        </md-dialog>

        <md-button
            class="md-primary md-raised BTN-info"
            @click="showDialog = true"
        >
            <img
                src="/img/default-avatar.png"
                alt=""
            />
            {{emailAddress}}
            <md-icon>arrow_drop_down</md-icon>
        </md-button>
    </div>
</template>

<script>
import { Api } from "@/api/index";
import utils from "@/utils";
import axios from "axios";

const getUserInfo = Api.get("userInfo");
const logOut = Api.get("logOut");
export default {
    name: "InfoUserDialog",
    data: () => ({
        showDialog: false,
        userName: "",
        emailAddress: "",
        phoneNumber: "",
        language: "en",
        firebaseToken: ""
    }),
    watch: {
    },
    mounted() {
        if (localStorage.lang == "vn") {
            this.language = "vn"
            this.useVietNam = true
        } else {
            this.language = "en"
            this.useVietNam = false
        }
        this.getInfo()
        this.firebaseToken = localStorage.getItem("firebaseToken")
    },
    methods: {
        logoutMethod() {
            const requestBody = {
                "firebaseToken": this.firebaseToken
            }
            logOut.post(requestBody).then((response) => {
                utils.logout("")
                this.$router.push({ name: "Login" });
            });
        },
        getInfo() {
            getUserInfo.get().then((response) => {
                this.userName = response.data.data.name;
                this.emailAddress = response.data.data.email;
                this.phoneNumber = response.data.data.phone_number;
            });
        },
    }
};
</script>
<style lang="scss" scoped>
.content-dialog {
    width: 80vh;
    padding: 16px;
    border-radius: 16px;
    .header {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        img {
            width: 15vh;
            height: 15vh;
            border-radius: 50%;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
        .Username {
            font-size: 24px;
            font-weight: 400;
            line-height: 32px;
            color: #11263c;
            text-align: center;
            margin-top: 8px;
        }
        .position {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            margin-top: 4px;
            color: #8c8c8c;
            text-align: center;
        }
    }
    .contact {
        width: 100%;
        margin-top: 8px;
        .phone {
            display: flex;
            flex-direction: column;
        }
        .mail {
            display: flex;
            flex-direction: column;
        }
        .address {
            display: flex;
            flex-direction: column;
        }
    }
    .title {
        font-size: 16px;
        line-height: 20px;
        color: #11263c;
        font-weight: 500;
        margin-top: 8px;
    }
    .value {
        font-size: 16px;
        line-height: 20px;
        color: #8c8c8c;
        font-weight: 400;
        margin-top: 4px;
    }
}
.avatar {
    width: 224px !important;
    height: 44px !important;
    .BTN-info {
        margin: 0;
        background-color: #fff !important;
        color: #000 !important;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        font-weight: 400;
        div:first-child {
            padding: 16px 6px !important;
        }
        .md-ripple {
            img {
                width: 32px;
                height: 32px;
                border-radius: 8px;
                margin-right: 8px;
            }
            .md-icon {
                margin-left: 8px;
                color: #000 !important;
            }
        }
    }
}
</style>
