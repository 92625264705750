import axios from "axios";

const resource = "/user-management/login"

export default {
    post(data) {
        return axios.post(resource, data, {
            headers: {
                "Content-Type": 'application/json'
            }
        })
    }
}