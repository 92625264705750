import axios from 'axios'

const resource = "user-management/update-password";

export default {
    put(data) {
        return axios.put(resource, data, {
            "Content-Type": "application/json"
        })
    }
}